
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
  },
  setup() {
    const timezone = ref(utcZonesOptions[16])
    return {
      timezone,
    }
  },
})
